
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonRippleEffect,
  // IonItem,
  // IonLabel,
  // IonInput,
  IonText,
  IonButton,
  IonSelect,
  IonSelectOption,
  modalController,
} from "@ionic/vue";
import axios from "axios";
import ModalInfo from "@/components/partial/ModalInfo.vue";

export default defineComponent({
  name: "Home",

  components: {
    IonPage,
    IonContent,
    IonRippleEffect,
    // IonItem,
    // IonLabel,
    // IonInput,
    IonText,
    IonButton,
    IonSelect,
    IonSelectOption,
  },

  data() {
    return {
      banners: [],
      today: {
        menu: {},
      },
      months: [] as any,
      month: "",
      packages: [] as any,
    };
  },

  watch: {
    month() {
      this.getMenuWeek();
    },
  },

  methods: {
    async refreshHome(event: any) {
      await this.getBanner();
      await this.getMenuToday();

      event.target.complete();
    },
    async openModal(menu: any) {
      const modal = await modalController.create({
        component: ModalInfo,
        componentProps: {
          menu: menu,
        },
      });
      modal.present();
    },
    async getBanner() {
      await axios.get("banner").then(({ data }) => {
        this.banners = data.data;
      });
    },

    async getMenuToday() {
      await axios.get("menu-today").then(({ data }) => {
        if (data) {
          this.today.menu = data.data[0];
        }
      });
    },
    async getMonth() {
      await axios.get("month-list").then(({ data }) => {
        this.months = data.data;
        this.month = this.months[0].month_id;
      });
    },
    async getMenuWeek() {
      const formData = new FormData();
      formData.append("month_id", this.month);
      await axios.post("menu-week", formData).then(({ data }) => {
        this.packages = data.bulan;
      });
    },
    listenerDraggable() {
      const slider = document.querySelector(".container-banner") as any;
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e: any) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },
    setItemRef(el) {
      const slider = el as any;
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e: any) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
    },
  },

  mounted() {
    this.getBanner();
    this.getMenuToday();
    this.getMonth();
    this.listenerDraggable();
  },
});
