<template>
  <ion-content :fullscreen="true">
    <div
      class="w-full bg-light bg-cover bg-no-repeat bg-center"
      style="height: 30vh"
      :style="{ backgroundImage: 'url(' + menu.gambar + ')' }"
    />
    <div class="flex" v-if="menu.gambar1 || menu.gambar2">
      <div class="w-full">
        <div
          class="w-full bg-light bg-cover bg-no-repeat bg-center"
          style="height: 20vh"
          :style="{ backgroundImage: 'url(' + menu.gambar1 + ')' }"
        ></div>
      </div>
      <div class="w-full">
        <div
          class="w-full bg-light bg-cover bg-no-repeat bg-center"
          style="height: 20vh"
          :style="{ backgroundImage: 'url(' + menu.gambar2 + ')' }"
        ></div>
      </div>
    </div>
    <div
      class="px-2 pt-8 flex ion-padding items-center"
      style="justify-content: space-between"
    >
      <ion-text
        class="font-bold"
        style="font-size: 24px; text-transform: capitalize"
      >
        {{ menu.menu }}
      </ion-text>
    </div>
    <div class="px-2">
      <ion-text>
        {{ menu.ket }}
      </ion-text>
    </div>
    <div style="position: fixed; bottom: 0; right: 0; left: 0; width: 100%">
      <ion-button @click="closeModal" expand="full" size="large">
        Tutup
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import { IonContent, IonText, modalController } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ModalInfo",
  props: {
    menu: { type: Object, default: null },
  },
  components: { IonContent, IonText },
  methods: {
    async closeModal() {
      await modalController.dismiss();
    },
  },
});
</script>